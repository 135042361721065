<template>
    <el-dialog
        v-model="show"
        title="派单"
        width="500px"
    >
        <div v-loading="loading" class="task-work-order-dispatch">
            <div class="basic-info">
                <el-form label-position="left" label-width="100px">
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="技师姓名：">
                                <el-select
                                    v-model="technicianId"
                                    clearable
                                    filterable
                                    placeholder="请选择技师"
                                    @change="changeTechnician"
                                    @clear="clearTechnician"
                                >
                                    <el-option
                                        v-for="(item,index) in technicianList"
                                        :key="index"
                                        :label="item.realname"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="技师手机号：">
                                <el-input v-model="technicianDetail.phone" disabled placeholder="请输入技师手机号"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="救援车牌：">
                                <el-input v-model="technicianDetail.carNumber" disabled
                                          placeholder="请输入救援车牌"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </div>
        <template v-if="!loading" #footer>
            <el-button @click="show = false">取消</el-button>
            <el-button type="primary" @click="submit()">确认提交</el-button>
        </template>
    </el-dialog>
</template>

<script>
import {onMounted, reactive, toRefs, watch} from "vue";
import {ADD_TICKETS_TASK, GET_USER_LIST} from "../../../api/dataProvider";
import {ElMessage} from "element-plus";

export default {
    name: "ECIFTaskWorkOrderDispatch",
    props: {
        show: Boolean,
        id: "",
    },
    emits: ["updateList", "update:show"],
    setup(props, context) {
        let state = reactive({
            loading: false,
            technicianId: "",
            technicianDetail: "",
            technicianList: [],
        })

        onMounted(() => {
            if (props.id) {
                getTechnicianList()
            }
        })

        watch(() => props.show, (val) => {
            context.emit("update:show", val);
        })

        watch(() => props.id, (val) => {
            if (val) {
                getTechnicianList()
            }
        })

        // 获取技师列表
        let getTechnicianList = () => {
            GET_USER_LIST(
                {
                    roleId: 4,
                    size: -1
                },
                (res) => {
                    state.technicianList = res.data.data
                    console.log(res.data.data)
                }
            )
        }

        // 选中技师
        let changeTechnician = (val) => {
            state.technicianList.map((item) => {
                if (item.id === val) {
                    state.technicianDetail = {
                        ...item,
                        carNumber: item.cars.length > 0 ? item.cars[0].number : null
                    }
                    console.log(state.technicianDetail)
                }
            })
        }

        // 清除技师
        let clearTechnician = () => {
            state.technicianDetail = {}
        }

        // 提交
        let submit = () => {
            if (!state.technicianDetail) {
                ElMessage({
                    message: "请先选择技师！",
                    type: "error"
                });
                return false
            }
            ADD_TICKETS_TASK(
                {
                    ticket: props.id,
                    user: state.technicianDetail.id,
                    car: state.technicianDetail.cars.length > 0 ? state.technicianDetail.cars[0].id : null
                },
                (res) => {
                    if (res.data.status === 200) {
                        ElMessage({
                            message: "派单成功",
                            type: "success"
                        });
                    } else {
                        ElMessage({
                            message: res.data.message,
                            type: "error"
                        });
                    }
                    console.log(1111, res)
                }
            )
        }

        // 更新父级列表
        let updateList = () => {
            context.emit("updateList");
            context.emit("update:show", false);
        }

        return {
            ...toRefs(state),
            getTechnicianList,
            changeTechnician,
            clearTechnician,
            submit,
            updateList
        }
    },
}
</script>

<style lang="scss" scoped>
.task-work-order-dispatch {
    .el-form {
        .el-form-item {
            .el-select {
                width: 100%;
            }
        }
    }
}
</style>