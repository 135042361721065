<template>
    <div class="task-work-order-detail">
        <el-dialog
            v-model="show"
            title="编辑工单"
            width="80%"
        >
            <div class="task-work-order-detail">
                <el-tabs>
                    <el-tab-pane label="基本信息">
                        <div class="basic-info">
                            <el-form :model="taskDetail" label-position="left" label-width="100px">
                                <el-row :gutter="50">
                                    <el-col :span="8">
                                        <el-form-item label="工单编号：">
                                            <el-input v-model="taskDetail.number" placeholder="请输入工单编号"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="服务类型：">
                                            <el-select v-model="taskDetail.serviceType" placeholder="请选择服务类型"
                                                       @change="serviceTypeChange">
                                                <template
                                                    v-for="(item,index) in $store.getters.geetServiceTypeList"
                                                    :key="index"
                                                >
                                                    <el-option
                                                        v-if="item.platform==='ECIF'"
                                                        :label="item.label"
                                                        :value="item.value"
                                                    ></el-option>
                                                </template>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="保单号：">
                                            <el-input v-model="taskDetail.policyNumber" placeholder="请输入保单号"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="车牌号码：">
                                            <el-input v-model="taskDetail.plateNumber" placeholder="请输入车牌号码"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="车架号：">
                                            <el-input v-model="taskDetail.vin" placeholder="请输入车架号"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="车主姓名：">
                                            <el-input v-model="taskDetail.name" placeholder="请输入车主姓名"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="车主电话：">
                                            <el-input v-model="taskDetail.phone" placeholder="请输入车主电话"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="承保公司：">
                                            <el-input v-model="taskDetail.platform" disabled
                                                      placeholder="请输入承保公司"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="是否事故：">
                                            <el-select v-model="taskDetail.isAccident" placeholder="请选择是否事故">
                                                <el-option :value="false" label="非事故"></el-option>
                                                <el-option :value="true" label="事故"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="案件地址：">
                                            <el-input
                                                v-model="taskDetail.location"
                                                placeholder="请输入案件地址"
                                                @blur="searchInMap(taskDetail.location)"
                                            >
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="送达目的地：">
                                            <el-input
                                                v-model="taskDetail.destination"
                                                placeholder="请输入送达目的地"
                                            >
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="工单类别：">
                                            <el-select v-model="taskDetail.type" placeholder="请选择工单类别">
                                                <el-option label="服务受理" value="ACCEPT"></el-option>
                                                <el-option label="服务催促" value="URGE"></el-option>
                                                <el-option label="投诉" value="COMPLAIN"></el-option>
                                                <el-option label="补充" value="SUPPLY"></el-option>
                                                <el-option label="报销" value="PAY"></el-option>
                                                <el-option label="取消" value="CANCEL"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="工单状态：">
                                            <el-select v-model="taskDetail.status" disabled placeholder="请选择工单状态">
                                                <el-option
                                                    v-for="(item,index) in $store.state.task.statusList"
                                                    :label="item.label"
                                                    :value="item.value"
                                                >
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="报案时间：">
                                            <el-date-picker
                                                v-model="taskDetail.reportedAt"
                                                placeholder="选择日期时间"
                                                type="datetime"
                                                @change="changeReportedAt"
                                            >
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24">
                                        <div id="container" class="form-map">
                                        </div>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-form-item label="备注：">
                                            <el-input
                                                v-model="taskDetail.comment"
                                                :rows="4"
                                                placeholder="请输入备注"
                                                type="textarea"
                                            >
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <!--                                    <el-col :span="24">-->
                                    <!--                                        <el-form-item label="服务内容：">-->
                                    <!--                                            <el-input-->
                                    <!--                                                v-model="form.name"-->
                                    <!--                                                :rows="4"-->
                                    <!--                                                placeholder="请输入服务内容（取消原因）"-->
                                    <!--                                                type="textarea"-->
                                    <!--                                            >-->
                                    <!--                                            </el-input>-->
                                    <!--                                        </el-form-item>-->
                                    <!--                                    </el-col>-->
                                    <!--                                    <el-col :span="8">-->
                                    <!--                                        <el-form-item label="任务类型：">-->
                                    <!--                                            <el-input v-model="form.name" placeholder="请输入任务类型"></el-input>-->
                                    <!--                                        </el-form-item>-->
                                    <!--                                    </el-col>-->
                                    <!--                                    <el-col :span="8">-->
                                    <!--                                        <el-form-item label="查勘员名称：">-->
                                    <!--                                            <el-input v-model="form.name" placeholder="请输入查勘员名称"></el-input>-->
                                    <!--                                        </el-form-item>-->
                                    <!--                                    </el-col>-->
                                    <!--                                    <el-col :span="8">-->
                                    <!--                                        <el-form-item label="查勘员手机：">-->
                                    <!--                                            <el-input v-model="form.name" placeholder="请输入查勘员手机"></el-input>-->
                                    <!--                                        </el-form-item>-->
                                    <!--                                    </el-col>-->
                                    <!--                                    <el-col :span="8">-->
                                    <!--                                        <el-form-item label="省市区：">-->
                                    <!--                                            <el-row justify="space-between" type="flex">-->
                                    <!--                                                <el-col :span="7">-->
                                    <!--                                                    <el-input v-model="form.name" placeholder="省"></el-input>-->
                                    <!--                                                </el-col>-->
                                    <!--                                                <el-col :span="7">-->
                                    <!--                                                    <el-input v-model="form.name" placeholder="市"></el-input>-->
                                    <!--                                                </el-col>-->
                                    <!--                                                <el-col :span="7">-->
                                    <!--                                                    <el-input v-model="form.name" placeholder="区"></el-input>-->
                                    <!--                                                </el-col>-->
                                    <!--                                            </el-row>-->
                                    <!--                                        </el-form-item>-->
                                    <!--                                    </el-col>-->
                                    <!--                                    <el-col :span="8">-->
                                    <!--                                        <el-form-item label="任务状态：">-->
                                    <!--                                            <el-input v-model="form.name" placeholder="请输入任务状态"></el-input>-->
                                    <!--                                        </el-form-item>-->
                                    <!--                                    </el-col>-->
                                    <!--                                    <el-col :span="8">-->
                                    <!--                                        <el-form-item label="救援状态：">-->
                                    <!--                                            <el-input v-model="form.name" placeholder="请输入救援状态"></el-input>-->
                                    <!--                                        </el-form-item>-->
                                    <!--                                    </el-col>-->
                                    <!--                                    <el-col :span="8">-->
                                    <!--                                        <el-form-item label="救援金额：">-->
                                    <!--                                            <el-input v-model="form.name" placeholder="请输入救援金额"></el-input>-->
                                    <!--                                        </el-form-item>-->
                                    <!--                                    </el-col>-->
                                    <!--                                    <el-col :span="8">-->
                                    <!--                                        <el-form-item label="救援项目：">-->
                                    <!--                                            <el-select v-model="form.region" placeholder="请选择救援项目">-->
                                    <!--                                                <el-option label="搭电" value="1"></el-option>-->
                                    <!--                                                <el-option label="拖车" value="2"></el-option>-->
                                    <!--                                            </el-select>-->
                                    <!--                                        </el-form-item>-->
                                    <!--                                    </el-col>-->
                                    <!--                                    <el-col :span="8">-->
                                    <!--                                        <el-form-item label="是否预约单：">-->
                                    <!--                                            <el-select v-model="form.region" placeholder="请选择救援项目">-->
                                    <!--                                                <el-option label="是" value="1"></el-option>-->
                                    <!--                                                <el-option label="否" value="2"></el-option>-->
                                    <!--                                            </el-select>-->
                                    <!--                                        </el-form-item>-->
                                    <!--                                    </el-col>-->
                                    <!--                                    <el-col :span="8">-->
                                    <!--                                        <el-form-item label="预约时间：">-->
                                    <!--                                            <el-input v-model="form.name" placeholder="0000-00-00 00:00:00"-->
                                    <!--                                                      readonly></el-input>-->
                                    <!--                                        </el-form-item>-->
                                    <!--                                    </el-col>-->
                                </el-row>
                            </el-form>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="辅助信息">
                        <div class="other-info">
                            <!--<div v-if="taskDetail.isOnline" class="upload-files">-->
                            <!--    <el-upload-->
                            <!--        :before-upload="beforeUpload"-->
                            <!--        :file-list="filePreviewList"-->
                            <!--        :http-request="addFiles"-->
                            <!--        action=""-->
                            <!--        list-type="picture-card"-->
                            <!--        multiple-->
                            <!--        ref="uploadDom"-->
                            <!--    >-->
                            <!--        <i class="el-icon-plus"></i>-->
                            <!--    </el-upload>-->
                            <!--    <div class="toolbar">-->
                            <!--        <el-select v-model="fileName" placeholder="请选择文件类型">-->
                            <!--            <el-option-->
                            <!--                v-for="(item,index) in fileNameList"-->
                            <!--                :key="item.value"-->
                            <!--                :label="item.label"-->
                            <!--                :value="item.value">-->
                            <!--            </el-option>-->
                            <!--        </el-select>-->
                            <!--        <el-button @click="uploadFile">上传</el-button>-->
                            <!--        <el-button @click="pushFile" type="primary">回传</el-button>-->
                            <!--    </div>-->
                            <!--</div>-->
                            <div class="images">
                                <img v-for="(item,index) in imageList" :key="index" :src="item.url">
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <template #footer>
                <el-button @click="show = false">取消</el-button>
                <el-button type="primary" @click="edit()">修改</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {reactive, ref, toRefs, watch} from "vue";
import AMapLoader from "@amap/amap-jsapi-loader";
import {GET_TICKETS_DETAIL} from "@/api/dataProvider";
import {ElMessage, ElMessageBox} from "element-plus";
import {
    GET_FILES,
    PUSH_FILES,
    UPDATE_SERVICE_TYPE,
    UPDATE_TICKETS,
    UPDATE_TICKETS_STATUS,
    UPLOAD_FILES
} from "../../../api/dataProvider";
import useFormatDateTime from "../../../hooks/useFormatDateTime";

export default {
    name: "ECIFTaskWorkOrderDetail",
    props: {
        show: Boolean,
        id: "",
    },
    emits: ["update:show"],
    setup(props, context) {
        let state = reactive({
            taskDetail: {},
            imageList: [],
            newServiceType: null
        })

        watch(() => props.show, (val) => {
            context.emit("update:show", val);
            if (val) {
                getTaskDetail()
            }
        })

        // 修改工单状态为read
        let changeTaskStatus = () => {
            return new Promise((resolve) => {
                UPDATE_TICKETS_STATUS(
                    {
                        tickets: [props.id],
                        status: "READ"
                    },
                    (res) => {
                        console.log(res.data)
                        if (res.data.status === 200) {
                            resolve(res)
                        } else {
                            ElMessage({
                                message: "修改工单状态失败！",
                                type: "error"
                            });
                        }
                    }
                )
            })
        }

        // 获取工单详情
        let getTaskDetail = () => {
            // 获取图片列表
            GET_FILES(
                {
                    ticketId: props.id
                },
                (res) => {
                    if (res.data.status === 200) {
                        state.imageList = res.data.data
                    }
                }
            )
            // 获取详情
            GET_TICKETS_DETAIL(props.id, (res) => {
                if (res.data.status === 200) {
                    if (res.data.data.status === "WAITING") {
                        changeTaskStatus().then((status) => {
                            res.data.data.status = "READ"
                        })
                    }
                    console.log(res.data.data)
                    state.taskDetail = res.data.data
                    searchInMap(state.taskDetail.location)
                }
            })
        }

        // 高德地图搜索
        let searchInMap = (val) => {
            AMapLoader.load({
                "key": "7023740654d6ddc40d0332d261beb213",   // 申请好的Web端开发者Key，首次调用 load 时必填
                "version": "1.4.15",                         // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                "plugins": [],                               // 需要使用的的插件列表，如比例尺"AMap.Scale"等
                "AMapUI": {                                  // 是否加载 AMapUI，缺省不加载
                    "version": "1.1",                        // AMapUI 缺省 1.1
                    "plugins": [],                           // 需要加载的 AMapUI ui插件
                },
                "Loca": {                                    // 是否加载 Loca， 缺省不加载
                    "version": "1.3.2"                       // Loca 版本，缺省 1.3.2
                },
            }).then((AMap) => {
                new AMap.Map("container", {});
                AMap.plugin("AMap.Geocoder", () => {
                    var geocoder = new AMap.Geocoder({})
                    geocoder.getLocation(val, (status, result) => {
                        if (status === "complete" && result.info === "OK") {
                            searchInMapSelect(result.geocodes[0])
                            ElMessage({
                                message: "地图加载成功",
                                type: "success"
                            });
                        } else {
                            ElMessage({
                                message: "地图加载失败，请重试！",
                                type: "error"
                            });
                        }
                    })
                })
            }).catch(e => {
            })
        }

        // 高德地图选中地址
        let searchInMapSelect = (item) => {
            state.taskDetail.latitude = item.location.lat
            state.taskDetail.longitude = item.location.lng
            let map = new AMap.Map("container", {
                zoom: 15,
                center: [item.location.lng, item.location.lat]
            });
            let marker = new AMap.Marker("container", {
                position: new AMap.LngLat(item.location.lng, item.location.lat),
                title: item.name,
            });
            map.add(marker);
        }

        let {formatDateTime} = useFormatDateTime()

        // 更改报案时间
        let changeReportedAt = (val) => {
            console.log(formatDateTime(val))
            state.taskDetail.reportedAt = formatDateTime(val)
        }

        // 服务类别切换
        let serviceTypeChange = (val) => {
            state.newServiceType = val
            ElMessageBox.confirm(
                "请选择替换或增补服务类型",
                "提示",
                {
                    confirmButtonText: "替换",
                    cancelButtonText: "增补",
                }
            ).then(() => {
                ElMessageBox.prompt("请输入服务内容描述", "修改服务类型", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    inputType: "textarea"
                }).then(({value}) => {
                    UPDATE_SERVICE_TYPE(
                        state.taskDetail.id,
                        {
                            serviceType: state.newServiceType,
                            serviceContent: value,
                            caseType: "change"
                        },
                        (res) => {
                            if (res.data.status === 200) {
                                ElMessage({
                                    type: "success",
                                    message: "修改服务类型成功!"
                                });
                            } else {
                                ElMessage({
                                    type: "error",
                                    message: "修改服务类型失败，请重试！"
                                });
                            }
                        }
                    )
                })
            }).catch(() => {
                ElMessageBox.prompt("请输入服务内容描述", "修改服务类型", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    inputType: "textarea"
                }).then(({value}) => {
                    UPDATE_SERVICE_TYPE(
                        state.taskDetail.id,
                        {
                            serviceType: state.newServiceType,
                            serviceContent: value,
                            caseType: "add"
                        },
                        (res) => {
                            if (res.data.status === 200) {
                                ElMessage({
                                    type: "success",
                                    message: "修改服务类型成功!"
                                });
                            } else {
                                ElMessage({
                                    type: "error",
                                    message: "修改服务类型失败，请重试！"
                                });
                            }
                        }
                    )
                })
            })
        }

        // 提交编辑
        let edit = () => {
            UPDATE_TICKETS(
                {
                    ...state.taskDetail
                },
                (res) => {
                    if (res.data.status === 200) {
                        ElMessage({
                            message: "修改成功!",
                            type: "success",
                        });
                        context.emit("update:show", false);
                    } else {
                        ElMessage({
                            message: "新增失败，请检查后重试！",
                            type: "error"
                        });
                    }
                }
            )
        }

        // 上传文件
        let filePreviewList = ref([])

        let fileUploadList = ref([])

        let fileName = ref(null)

        let fileNameList = ref([
            {
                label: "现场照片",
                value: "现场照片",
            },
            {
                label: "确认书",
                value: "确认书",
            },
            {
                label: "行驶证/车架号",
                value: "行驶证/车架号",
            },
            {
                label: "路况照片",
                value: "路况照片",
            }
        ])

        let beforeUpload = (file) => {
            console.log(file)
            let types = ["image/jpg", "image/jpeg"];
            let isImage = types.includes(file.type);
            if (!isImage) {
                ElMessage({
                    message: "上传图片只能是JPG格式！",
                    type: "error"
                });
                return false
            }
        }

        let uploadFile = () => {
            let formData = new FormData()

            formData.append("taskId", state.taskDetail.activeTasks[0].id)
            formData.append("name", fileName.value)
            fileUploadList.value.map((item) => {
                formData.append("files", item)
            })

            UPLOAD_FILES(
                formData,
                (res) => {
                    console.log(1111, res)
                    if (res.data.status === 200) {
                        ElMessage({
                            message: "上传成功!",
                            type: "success",
                        });
                        filePreviewList.value = []
                        fileUploadList.value = []
                        fileName.value = null
                    } else {
                        ElMessage({
                            message: "上传失败，请检查后重试！",
                            type: "error"
                        });
                    }
                }
            )
        }

        let addFiles = (file) => {
            console.log(file)
            fileUploadList.value.push(file.file)
            console.log(11111, fileUploadList.value)
        }

        let uploadDom = ref(null)

        let pushFile = () => {
            PUSH_FILES(
                {
                    id: state.taskDetail.activeTasks[0].id
                },
                (res) => {
                    console.log(1111, res)
                    if (res.data.status === 200) {
                        ElMessage({
                            message: "回传成功",
                            type: "success"
                        });
                        uploadDom.value.clearFiles()
                        getTaskDetail()
                    } else {
                        ElMessage({
                            message: "回传失败，请检查后重试！",
                            type: "error"
                        });
                    }
                }
            )
        }

        return {
            ...toRefs(state),
            searchInMap,
            searchInMapSelect,
            changeTaskStatus,
            getTaskDetail,
            formatDateTime,
            changeReportedAt,
            serviceTypeChange,
            edit,
            fileName,
            fileNameList,
            filePreviewList,
            fileUploadList,
            beforeUpload,
            uploadFile,
            addFiles,
            uploadDom,
            pushFile
        }
    },
}
</script>

<style lang="scss" scoped>
.task-work-order-detail {
    ::v-deep(.el-form) {
        .el-form-item {
            .el-select {
                width: 100%;
            }

            .el-input {
                width: 100%;
            }

            .el-autocomplete {
                width: 100%;
            }
        }
    }

    .basic-info {
        padding-top: 15px;

        .form-map {
            width: 100%;
            height: 500px;
            margin-bottom: 22px;
        }
    }

    .other-info {
        .upload-files {
            .toolbar {
                margin: 15px 0;

                .el-button {
                    margin-left: 15px;
                }
            }
        }

        .images {
            img {
                max-width: 100%;
                display: block;
                margin: 20px auto 0 auto;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}
</style>