<template>
    <div class="task-work-order-list">
        <el-tabs>
            <el-tab-pane label="线上工单">
                <TaskWorkOrderListOnline
                    @dispatch="openDispatchDialog"
                    @edit="openDetailDialog"
                    @locus="openLocusDialog"
                >
                </TaskWorkOrderListOnline>
            </el-tab-pane>
            <el-tab-pane label="线下工单">
                <TaskWorkOrderListOffline
                    @dispatch="openDispatchDialog"
                    @edit="openDetailDialog"
                    @locus="openLocusDialog"
                >
                </TaskWorkOrderListOffline>
            </el-tab-pane>
        </el-tabs>
    </div>
    <!--查看/编辑弹窗-->
    <TaskWorkOrderDetail :id="detailDialog.id" v-model:show="detailDialog.show"></TaskWorkOrderDetail>
    <!--派单弹窗-->
    <TaskWorkOrderDispatch :id="dispatchDialog.id" v-model:show="dispatchDialog.show"></TaskWorkOrderDispatch>
    <!--轨迹弹窗-->
    <TaskWorkOrderLocus :id="locusDialog.id" v-model:show="locusDialog.show"></TaskWorkOrderLocus>
</template>

<script>
import {reactive, toRefs} from "vue";
import TaskWorkOrderListOnline from "./TaskWorkOrderListOnline.vue";
import TaskWorkOrderListOffline from "./TaskWorkOrderListOffline.vue";
import TaskWorkOrderDetail from "./TaskWorkOrderDetail.vue";
import TaskWorkOrderDispatch from "./TaskWorkOrderDispatch.vue";
import TaskWorkOrderLocus from "./TaskWorkOrderLocus.vue";
import {useStore} from "vuex";

export default {
    name: "ECIFTaskWorkOrderList",
    components: {
        TaskWorkOrderListOnline,
        TaskWorkOrderListOffline,
        TaskWorkOrderDetail,
        TaskWorkOrderDispatch,
        TaskWorkOrderLocus
    },
    setup() {
        let $store = useStore()

        let state = reactive({
            // 详情弹窗
            detailDialog: {
                show: false,
                id: null
            },
            // 派单弹窗
            dispatchDialog: {
                show: false,
                id: null
            },
            // 派单弹窗
            locusDialog: {
                show: false,
                id: null
            }
        })

        // 打开详情弹窗
        let openDetailDialog = (id) => {
            state.detailDialog.id = id
            state.detailDialog.show = true

        }

        // 打开派单弹窗
        let openDispatchDialog = (id) => {
            state.dispatchDialog.id = id
            state.dispatchDialog.show = true
        }

        // 打开派单弹窗
        let openLocusDialog = (id) => {
            state.locusDialog.id = id
            state.locusDialog.show = true
        }

        return {
            ...toRefs(state),
            openDetailDialog,
            openDispatchDialog,
            openLocusDialog
        }
    },
}
</script>

<style lang="scss" scoped>
.task-work-order-list {

}
</style>
