<template>
    <div class="search-toolbar">
        <div class="tool-item">
            <span class="item-label">任务号：</span>
            <el-input
                v-model="searchParams.number"
                class="item-input"
                clearable
                placeholder="请输任务号"
            >
            </el-input>
        </div>
        <div class="tool-item">
            <span class="item-label">车牌号码：</span>
            <el-input
                v-model="searchParams.plateNumber"
                class="item-input"
                clearable
                placeholder="请输车牌号码"
            >
            </el-input>
        </div>
        <div class="tool-item">
            <span class="item-label">车架号：</span>
            <el-input
                v-model="searchParams.vin"
                class="item-input"
                clearable
                placeholder="请输车架号"
            >
            </el-input>
        </div>
        <div class="tool-item">
            <span class="item-label">用户电话：</span>
            <el-input
                v-model="searchParams.phone"
                class="item-input"
                clearable
                placeholder="请输用户电话"
            >
            </el-input>
        </div>
        <div class="tool-item tool-buttons">
            <el-button type="primary" @click="getTaskList()">搜索</el-button>
            <!--<el-button>重置</el-button>-->
        </div>
    </div>
    <div class="table-container">
        <div class="table-wrapper">
            <el-table
                :cell-style="{textAlign:'center'}"
                :data="taskList"
                :header-cell-style="{background:'#409EFF',color:'#fff',textAlign:'center'}"
            >
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column
                    label="工单号"
                    prop="number"
                >
                </el-table-column>
                <el-table-column
                    label="平台"
                    prop="platform"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    label="救援类型"
                >
                    <template #default="scope">
                        {{ getLabel($store.state.task.serviceTypeList, scope.row.serviceType) }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="工单状态"
                >
                    <template #default="scope">
                        {{ getLabel($store.state.task.statusList, scope.row.status) }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="车牌号"
                    prop="plateNumber"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    label="车主姓名"
                    prop="name"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    label="联系电话"
                    prop="phone"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    label="报案时间"
                    prop="createdAt"
                    width="180"
                >
                </el-table-column>
                <el-table-column
                    label="报案号"
                    prop="caseReportNo"
                    width="180"
                >
                </el-table-column>
                <el-table-column
                    label="救援地址"
                    prop="location"
                >
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="360"
                >
                    <template #default="scope">
                        <el-button
                            :disabled="scope.row.status==='COMPLETED'"
                            size="mini"
                            @click="openCompleteDialog(scope.row.id)"
                        >
                            完工
                        </el-button>
                        <el-button size="mini" @click="openEditDialog(scope.row.id)">详情</el-button>
                        <el-button
                            :disabled="scope.row.status==='COMPLETED' || scope.row.status==='CANCELLED' || scope.row.status==='CANCELLED_FREE' ? true:false"
                            size="mini"
                            @click="openDispatchDialog(scope.row.id)"
                        >
                            {{
                                scope.row.status === "READ" || scope.row.status === "WAITING" || scope.row.status === "CANCELLED" || scope.row.status === "CANCELLED_FREE" ? "派单" : "改派"
                            }}
                        </el-button>
                        <el-button
                            :disabled="scope.row.status!=='WAITING'&&scope.row.status!=='READ'&&scope.row.status!=='REDISPATCHING'?false:true"
                            size="mini"
                            @click="openLocusDialog(scope.row.id)"
                        >轨迹
                        </el-button>
                        <el-button
                            :disabled="scope.row.status==='COMPLETED' || scope.row.status==='CANCELLED' || scope.row.status==='CANCELLED_FREE' ? true:false"
                            size="mini"
                            @click="cancelTicket(scope.row.id)"
                        >取消
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-wrapper">
            <el-pagination
                :current-page="page.page"
                :page-size="page.size"
                :total="taskTotal"
                background
                layout="sizes,prev, pager, next,jumper"
                @current-change="changePageCurrent"
                @size-change="changePageSize"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
import {onMounted, reactive, toRefs} from "vue";
import {GET_TICKETS_LIST} from "@/api/dataProvider";
import {useStore} from "vuex";
import {CANCEL_TICKETS_STATUS, UPDATE_TICKETS_STATUS} from "../../../api/dataProvider";
import {ElMessage, ElMessageBox} from "element-plus";

export default {
    name: "ECIFTaskWorkOrderListOnline",
    emits: ["edit", "dispatch", "locus"],
    setup(props, context) {
        let $store = useStore()

        let state = reactive({
            searchParams: {
                number: null,
                plateNumber: null,
                vin: null,
                phone: null
            },
            taskList: [],
            taskSelection: [],
            taskTotal: 0,
            page: {
                page: 1,
                size: 10,
            },
        })

        onMounted(() => {
            getTaskList(false)
            setInterval(() => {
                getTaskList(true)
                console.log("refresh", new Date().getHours() + ":" + new Date().getMinutes())
            }, 30000)
        })

        // 获取工单列表
        let getTaskList = (status) => {
            GET_TICKETS_LIST(
                {
                    ...state.page,
                    ...state.searchParams,
                    platform: "ECIF",
                    isOnline: true
                },
                (res) => {
                    if (res.data.status === 200) {
                        state.taskTotal = res.data.page.total
                        state.taskList = res.data.data
                        if (res.data.page.total > $store.getters.getECIFTaskCount) {
                            if (status) {
                                let audio = new Audio("https://sky-1251058522.file.myqcloud.com/music/ecif.mp3")
                                audio.play();
                            }
                        }
                        $store.commit("setECIFTaskCount", res.data.page.total)
                    }
                })
        }

        // 修改分页当前页
        let changePageCurrent = (page) => {
            state.page.page = page
            getTaskList()
        }

        // 修改分页size
        let changePageSize = (size) => {
            state.page.size = size
            getTaskList()
        }

        // 完工操作
        let openCompleteDialog = (id) => {
            ElMessageBox.confirm("确定完工吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                UPDATE_TICKETS_STATUS(
                    {
                        tickets: [id],
                        status: "COMPLETED"
                    },
                    (res) => {
                        console.log(res.data)
                        if (res.data.status === 200) {
                            ElMessage({
                                type: "success",
                                message: "修改成功!"
                            });
                            getTaskList()
                        } else {
                            ElMessage({
                                message: res.data.message || "修改失败！",
                                type: "error"
                            });
                        }
                    }
                )
            })
        }

        // 打开编辑
        let openEditDialog = (id) => {
            context.emit("edit", id);
        }

        // 打开派单
        let openDispatchDialog = (id) => {
            context.emit("dispatch", id);
        }

        // 打开轨迹
        let openLocusDialog = (id) => {
            context.emit("locus", id);
        }

        // 取消工单
        let cancelTicket = (id) => {
            ElMessageBox.confirm("确认取消该工单吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                CANCEL_TICKETS_STATUS(
                    {
                        id: id,
                    },
                    (res) => {
                        if (res.data.status === 200) {
                            ElMessage({
                                type: "success",
                                message: "工单取消成功!"
                            });
                            getTaskList()
                        } else {
                            ElMessage({
                                type: "error",
                                message: "工单取消失败，请重试！"
                            });
                        }
                    }
                )
            }).catch(() => {
            });
        }

        // 获取label方法
        let getLabel = (list, value) => {
            let label
            list.map((item) => {
                if (item.value === value) {
                    label = item.label
                }
            })
            return label
        }

        return {
            ...toRefs(state),
            getTaskList,
            changePageCurrent,
            changePageSize,
            openCompleteDialog,
            openEditDialog,
            openDispatchDialog,
            openLocusDialog,
            cancelTicket,
            getLabel
        }
    },
}
</script>